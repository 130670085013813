<template>
  <div>
    <div v-if="tags.length > 0" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 py-5">
            <h3 class="card-title">
              <span class="card-label font-weight-bolder text-dark"
                >All Tags</span
              >
            </h3>
            <div class="card-toolbar">
              <router-link
                to="/tags/new"
                class="btn btn-mc font-weight-bolder font-size-sm"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg
                    src="media/svg/icons/Communication/Add-user.svg"
                  /> </span
                >Add New Tag
              </router-link>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-0">
            <!--begin::Table-->
            <div class="table-responsive">
              <table
                class="table table-head-custom table-head-bg table-vertical-center"
              >
                <thead>
                  <tr class="text-left text-uppercase">
                    <th style="min-width: 120px">Title</th>
                    <th style="min-width: 110px" class="pr-7 text-right">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(tag, key) in tags" :key="key">
                    <td class="pl-0" :class="{ 'border-top-0': key === 0 }">
                      <router-link
                        :to="'/tags/edit/' + tag.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ tag.title }}</router-link
                      >
                    </td>
                    <td class="pr-0 text-right">
                      <router-link
                        :to="'/tags/edit/' + tag.id"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Communication/Write.svg"
                          />
                        </span>
                      </router-link>
                      <button
                        @click="removeTag(tag.id)"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg src="media/svg/icons/General/Trash.svg" />
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12 text-center">
        <b-spinner
            variant="primary"
            type="grow"
        ></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'ListTags',
  data() {
    return {
      tags: [],
      totalItems: null
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Tags', route: '/tags/list' }
    ])

    ApiService.get(`/tags`, `?order[createdAt]=desc`).then(({ data }) => {
      this.totalItems = data['hydra:totalItems']
      this.tags = [...data['hydra:member']]
    })
  },
  methods: {
    removeTag(id) {
      const confirmation = confirm('You want delete this tag?')
      if (confirmation === false) return

      this.deleteTag(id).then(({ status }) => {
        if (status === 204) {
          this.tags = this.tags.filter(tag => tag.id !== id)
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Tag success deleted from database'
          })
        }
      })
    },
    async deleteTag(id) {
      try {
        return await ApiService.delete('/tags/' + id)
      } catch (e) {
        throw new Error(e)
      }
    }
  }
}
</script>
